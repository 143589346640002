.modal-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.modal-container > .container {
  text-align: left;
  width: 90%;
  padding-top: 20px;
  max-width: 500px;
  border-radius: 10px;
  background-color: #e0e5eb;
  position: absolute;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.modal-container > .container > .close {
  position: absolute;
  font-weight: bold;
  border: 1px solid #333c5f;
  color: #333c5f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.8em;
  line-height: 1.8em;
  cursor: pointer;
  transition: 0.2s;
  top: 15px;
  right: 15px;
  cursor: pointer;
  &:hover {
    background: #333c5f;
    border-color: #333c5f;
    color: #fff;
  }
}
.modal-container > .container > .copy {
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  border: none;
  background: #333c5f;
  color: #fff;
  border-radius: 0 0 10px 10px;
  &:hover {
    opacity: 0.8;
  }
}

/*
* TODO..以下のスタイルがmodal-container内に書かれているのは微妙なのでリファクタする
*/

.modal-container > .container > .inbox {
  width: 90%;
}

.modal-container > .container > .inbox > .code {
  max-height: 340px;
  overflow: scroll;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  resize: none;
  background-color: #282c34;
  &:focus {
    border: none;
    outline: 0;
  }
}

.modal-container > .container > .inbox > .html {
  text-align: left;
  overflow-x: scroll;
  margin-right: 1.8em;
  display: inline-block;
  color: #282c34;
}
