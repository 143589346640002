@import "styles/media.scss";
.footer {
  display: table;
  text-align: center;
  width: 100%;
  height: 6rem;
  @include tab {
    height: 5rem;
  }
}

.footer > .text {
  color: #333c5f;
  display: table-cell;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: bold;
}

.footer > .text > .link {
  color: #333c5f;
  border-bottom: 1px solid #333c5f;
  margin-right: 10px;
}
