@import "styles/media.scss";
.main-container {
  padding-top: 30px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: calc(100vh - 14rem);
  @include tab {
    min-height: calc(100vh - 12rem);
  }
  > .description {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #333c5f;
    @include sp {
      font-size: 1.7rem;
    }
  }
}
