@import "styles/media.scss";
.header {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  display: table;
  text-align: center;
  width: 100%;
  height: 6rem;
  position: relative;
  @include tab {
    height: 5rem;
  }
}

.header > .title {
  color: #333c5f;
  display: table-cell;
  vertical-align: middle;
  font-size: 2.5rem;
  font-weight: bold;
  @include tab {
    font-size: 1.8rem;
  }
}

.header > .button {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
  display: block;
  padding: 0.5rem 2rem;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-sizing: border-box;
  text-decoration: none;
  transition: 0.3s;
  color: #333c5f;
  font-weight: bold;
  &:hover {
    box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
      -2px -2px 4px rgba(255, 255, 255, 0.4),
      2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
  }
  @include tab {
    top: 1.2rem;
  }
  @include sp {
    display: none;
  }
}

/*
* SP用ハンバーガーメニュー
*/
.header > .sp-button {
  position: absolute;
  top: 1.5rem;
  right: 1.2rem;
  width: 40px;
  height: 34px;
  z-index: 10;
  cursor: pointer;
  display: none;
  @include sp {
    display: block;
  }
  &.-active {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    &.-active span:nth-of-type(1) {
      -webkit-transform: translateY(15px) rotate(-45deg);
      transform: translateY(15px) rotate(-45deg);
    }
    &.-active span:nth-of-type(2) {
      -webkit-transform: translateY(0) rotate(45deg);
      transform: translateY(0) rotate(45deg);
    }
    &.-active span:nth-of-type(3) {
      opacity: 0;
    }
  }
}
.header > .sp-button > span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #333c5f;
  border-radius: 4px;
}
.header > .sp-button,
.header > .sp-button > span {
  display: inline-block;
  transition: all 0.5s;
  box-sizing: border-box;
}
.header > .sp-button > span:nth-of-type(1) {
  top: 0;
}
.header > .sp-button > span:nth-of-type(2) {
  top: 15px;
}
.header > .sp-button > span:nth-of-type(3) {
  bottom: 0;
}

.header > .sp-button > span {
  display: none;
  @include sp {
    display: block;
  }
}

.header > .lists {
  border-radius: 8px;
  background: #e0e5eb;
  display: flex;
  z-index: 10;
  flex-direction: column;
  text-align: left;
  color: #333c5f;
  font-size: 1.4rem;
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem 2rem;
  position: absolute;
  top: 4rem;
  right: 2rem;
  list-style: none;
  cursor: pointer;
  @include sp {
    top: 3.5rem;
    right: 1rem;
  }
}

.header > .lists > .list {
  font-weight: bold;
  margin-bottom: 0.5rem;
  transition: 0.2s;
  &:hover {
    transform: scale(1.2);
  }
}
