.code-block-container {
  position: relative;
  transition: 0.3s;
  width: 200px;
  height: 200px;
  margin: 20px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
      -6px -6px 10px rgba(255, 255, 255, 0.5),
      6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);
  }
  > .title {
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: bold;
    font-size: 1.5rem;
    color: #333c5f;
  }
}
